import React, { useState, useEffect, useContext, useRef } from 'react'
import { sliderUrl } from '../const/const';
import mainContext from '../context/Context';
// import './owl.css';

const ImageSlider = () => {

  // fetch slider images from the specific folder based on current user's role

  const { userProfile } = useContext(mainContext);

  let user_role = (userProfile != null) ? userProfile.role : "guest";

  const [images, setImages] = useState([])

  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    
    const sliderFetchApi = await fetch(sliderUrl+"user_role="+user_role+"&webSlider=true&refresh="+Math.random())
      .then(async (res) => {
        const slides = await res.json();
        setImages(slides);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error)
      });

    return () => {
      
    }
  }, [])

  return (
// Slider START

<div className="slider">
   <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        {
          images.map((imageUrl, index) => {
            return(
              <div key={index} className={(index==0) ? "carousel-item active" : "carousel-item"}>
                <img className="d-block w-100" src={imageUrl} alt="" />
              </div>
            )
          })
        }
      </div>

      <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
      </a>
   </div>
</div>



// Slider END
  )
}

export default ImageSlider
