import React, { useEffect, useState, useContext } from 'react'
import LatestPosts from '../components/LatestPosts'
import PageHeader from '../components/PageHeader';
import { categories } from '../const/const'
import mainContext from '../context/Context';


export default function Training() {

    const { userProfile } = useContext(mainContext);
    const [trainingEndpoint, setTrainingEndpoint] = useState("");
    const [endpointCreated, setEndpointCreated] = useState(false)

    const [isDistanceLearningUser, setIsDistanceLearningUser] = useState(false)

    useEffect(() => {
        // console.log("user profile:", userProfile);

        switch (userProfile.role) {
            case 'playgroupclass':
                setTrainingEndpoint("posts?categories="+categories.TRAINING_PG);
                setEndpointCreated(true)
                break;

            case 'pre-nursery':
                setTrainingEndpoint("posts?categories="+categories.TRAINING_PN);
                setEndpointCreated(true)
                break;

            case 'pre-nurserydl1':
            case 'pre-nurserydl2':
            case 'pre-nurserydl3':
            case 'pre-nurserydl4':
            case 'pre-nurserydl5':
            case 'pre-nurserydl6':
            case 'pre-nurserydl7':
            case 'pre-nurserydl8':
            case 'pre-nurserydl9':
            case 'pre-nurserydl10':
            case 'pre-nurserydl11':
            case 'pre-nurserydl12':
                setTrainingEndpoint("posts?categories="+categories.TRAINING_PN+"&user_role=" + userProfile.role);

                // set that the user is distance learning user
                setIsDistanceLearningUser(true);
                setEndpointCreated(true)
                break;

            case 'playgroupdl1':
            case 'playgroupdl2':
            case 'playgroupdl3':
            case 'playgroupdl4':
            case 'playgroupdl5':
            case 'playgroupdl6':
            case 'playgroupdl7':
            case 'playgroupdl8':
            case 'playgroupdl9':
            case 'playgroupdl10':
            case 'playgroupdl11':
            case 'playgroupdl12':
                setTrainingEndpoint("posts?categories="+categories.TRAINING_PG+"&user_role=" + userProfile.role);

                // set that the user is distance learning user
                setIsDistanceLearningUser(true);
                setEndpointCreated(true)
                break;
        
            default:
                break;
        }
        
        return () => {
            
        }
    }, [])

    return (
        <div id="content-area" className="container">
            
            <PageHeader 
                headerImage={require('../assets/images/parents-lg-icon.png').default} 
                headerColor='#feccd6' 
                headerTitleSpan='training for ' 
                headerTitleName='PARENTS' 
            />

            {endpointCreated == true ? 
                (
                    <LatestPosts isDistanceLearningUser={isDistanceLearningUser} endpoint={trainingEndpoint} categoryName="parents" categoryIcon={require('../assets/images/parents-small.png').default} pagination={true}/>
                ): null
            }
        </div>
    )
}
