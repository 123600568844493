import {useState, useEffect} from 'react'
import { fetchApiData } from '../services/api';
import parse from 'html-react-parser';
import { postSlugs } from '../const/const';

export default function Forgot_Password({setShowLoginBox}) {

    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        console.log("hello");
        const forgotPasswordFetchAPI = fetchApiData("posts/?slug="+postSlugs.FORGOT_PASSWORD, false, true).then((jsonResponse) => {
            

            let whatsapp_message = null, whatsapp_number = null
                
                if(jsonResponse.data[0].whatsapp_message){
                    let whatsappMessageArray = jsonResponse.data[0].whatsapp_message.split("&phone=");

                    whatsapp_message = whatsappMessageArray[0];
                    whatsapp_number = whatsappMessageArray[1];
                }

            setData({
                title: jsonResponse.data[0].title.rendered,
                // description: decode(jsonResponse.data[0].content.rendered).replace(/<[^>]+>/g, ''),
                description: jsonResponse.data[0].content.rendered,
                whatsapp_message: whatsapp_message,
                whatsapp_number: whatsapp_number,
            })

            setIsLoading(false)
            
        }).catch(error => console.log("Error while fetching data:", error))

        return () => {
            
        }
    }, [])


    return (
        <div id="content-area" className="container">
            {
                (!isLoading) ? (
                    <>
                    <div className="login-form">
                        <div id="forgot-password">
                            <h1>{data.title}</h1>
                            {parse(data.description)}
                            <a href={encodeURI("https://wa.me/"+data.whatsapp_number+"?text="+data.whatsapp_message)} className="btn btn-success btn-block" >Whatsapp</a>
                            <button className="btn btn-block sign-in-link" onClick={() => {setShowLoginBox(true)}} >Sign In Instead</button>
                        </div>
                    </div>
                    </>
                ) : null
            }
            
        </div>
    )
}
