import React from 'react'

export default function PageHeader({headerImage, headerColor, headerTitleSpan, headerTitleName}) {
    return (
        <div className='page-header' style={{backgroundColor: headerColor}}>
            <h1><span className='page-header-title-span'>{headerTitleSpan}</span>{headerTitleName}</h1>
            <img className='page-header-image' src={headerImage} />
        </div>
    )
}
