import { domain } from '../const/const';

export const fetchApiData = async (route, distanceLearningUser = false, useDefaultWPRestAPIURL = false) => {

  let apiBaseURL;

  if(useDefaultWPRestAPIURL==true){
     apiBaseURL = domain + "wp-json/wp/v2/";
  }else{
    if(distanceLearningUser == true){
      apiBaseURL = domain + "wp-json/custom-rest-api/v1/DL/";
    }else{
      apiBaseURL = domain + "wp-json/custom-rest-api/v1/";
    }
  }

  

  try {
    const response = await fetch(apiBaseURL + route);
    const json = await response.json();
    return {data:json, totalPages:response.headers.get('x-wp-totalpages')};
  } catch (error) {
    console.log(error);

    return null;
  }
};