import React, { useState, useEffect } from 'react'
import { fetchApiData } from '../services/api';
import parse from 'html-react-parser';
import {decode} from 'html-entities';

import $ from 'jquery';

const LatestPosts = ({endpoint, pagination, isDistanceLearningUser, categoryName, categoryIcon}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [endpointState, setEndpointState] = useState(endpoint); //take endpoint from props and set it to local state (doing this to handle pagination for screens which requires pagination i.e: YouTube)
    const [totalPages, settotalPages] = useState()


    const handleNavNext = () => {
        setIsLoading(true)
        if(page < totalPages){
            setPage((prevState) => prevState + 1);
        }
    }

    useEffect(() => {
        if(page != 1){
            setEndpointState(endpoint+"&page="+page) 
        }
        return () => {
            
        }
    }, [page])
    

    useEffect(() => {

        setIsLoading(true);
        $('body').on('click', '.playVideoBtn', (e)=>{
            $('#videoPopupModal .modal-body').html('<iframe id="videoIframe" className="videoIframe" src="//www.youtube.com/embed/'+e.currentTarget.getAttribute('data-youtubevideoid')+'" allowfullscreen></iframe>')
            $('#videoPopupModalTitle').html(e.currentTarget.getAttribute('data-youtubevideotitle'))
            window.$('#videoPopupModal').modal('show')

        })

        window.$('#videoPopupModal').on('hidden.bs.modal', function (e) {
            $('#videoPopupModal .modal-body').html('')
        })
        

        const postDetailFetchingAPI = fetchApiData(endpointState, isDistanceLearningUser).then((jsonResponse) => {
            settotalPages(jsonResponse.totalPages);          
          
            let allPosts = [];
            let title, videoCategoryName, videoCategoryIcon;
            console.log(jsonResponse.data);

            jsonResponse.data.forEach(post => {
                

                // youtubeVideoId
                const postExcerpt = post.excerpt.rendered;
                let n = postExcerpt.indexOf("embed");
                let youtubeVideoId = postExcerpt.substr(n+6, 11)

                // post Title
                title = decode(post.title.rendered);
                if(!categoryName && !categoryIcon){
                    switch (post.categories[0]) {
                        case "videos-pn":
                        case "videos-pg":
                        // case 25: // remove this case only
                            videoCategoryName = "childrens";
                            videoCategoryIcon = require('../assets/images/childrens-small.png').default;
                            break;
                        
                        case "training-pn":
                        case "training-pg":
                        // case 24: //remove this case only
                            videoCategoryName = "parents";
                            videoCategoryIcon = require('../assets/images/parents-small.png').default;
                            break;
                    
                        default:
                            videoCategoryName = "youtube";
                            videoCategoryIcon = require('../assets/images/youtube-small.png').default;
                            break;
                    }

                    // post object
                    let postDetails = {
                        title: title,
                        youtubeVideoId: youtubeVideoId,
                        videoCategoryName: videoCategoryName,
                        videoCategoryIcon: videoCategoryIcon,

                    };

                    allPosts.push(postDetails)
                }else{
                    // post object
                    let postDetails = {
                        title: title,
                        youtubeVideoId: youtubeVideoId,
                        videoCategoryName: categoryName,
                        videoCategoryIcon: categoryIcon,

                    };

                    allPosts.push(postDetails)
                }
            });
            
            console.log(allPosts);
            // setPosts(allPosts);
            setPosts((prevPosts) => ([...prevPosts, ...allPosts] ));
            setIsLoading(false);
           
            
        }).catch(error => console.log("Error while fetching posts:", error))
        return () => {
            
        }
    }, [endpointState])
    

    

    return (
        <div className='postsListing'>

            {
                posts.map((post, index) => (
                    <div key={index} className={"post-item " + post.videoCategoryName}>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <td width="40%" className="post-thumb playVideoBtn" data-youtubevideotitle={post.title} data-youtubevideoid={post.youtubeVideoId}><img src={'https://img.youtube.com/vi/'+post.youtubeVideoId+'/0.jpg'}/></td>
                                    <td width="50%" className="post-title">
                                    <h2 className="video-title playVideoBtn" data-youtubevideotitle={post.title} data-youtubevideoid={post.youtubeVideoId}>{post.title}</h2>
                                    <div className="category"><img src={post.videoCategoryIcon}/> {post.videoCategoryName}</div>
                                    </td>
                                    <td width="10%" className="play-btn">
                                        <span className='playVideoBtn' data-youtubevideotitle={post.title} data-youtubevideoid={post.youtubeVideoId}>
                                            <img src={require('../assets/images/play-btn.png').default}/>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ))
            }

            <div className="mt-2">
                {
                    (!isLoading) ? 
                    (
                        (posts.length > 0) ? 
                        (
                            
                            (pagination==true) ?
                            (
                                (page < totalPages) ?
                                (
                                    
                                    <button type="button" className="btn btn-primary view-more-btn" onClick={handleNavNext}>
                                        View More
                                    </button>
                                
                                ) : <p>No More Posts</p>
                                
                            ) : null
                            
                        ): <p>There are no posts</p>
                    ) : <div className="spinner-border text-danger" style={{width: '4rem', height: '4rem'}}></div>
                }         
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="videoPopupModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="videoPopupModalTitle">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            ...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default LatestPosts