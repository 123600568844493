export default function Footer() {

    const _getYear = () => {
        var today = new Date()
        var year = today.getFullYear()
        return year;
    }

    return (
        // <!--Footer START-->
        <div className="footer">
            <div className="fcontent">
            <div className="left">
                Copyright © {_getYear()} — Sheeba’s Play Place. All rights reserved. | <a href="https://sheebasplayplace.com/privacy-policy" target="_blank">Privacy Policy</a>
            </div>
            <div className="right">Developed By: <a href="https://eteamid.com" target="_blank">Enterprise Team</a></div>
            </div>
        </div>
        // <!--Footer End-->
    )
}
