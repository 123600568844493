import {useState, useEffect} from 'react'
import { fetchApiData } from '../services/api';
import parse from 'html-react-parser';
import PageHeader from '../components/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faFileAlt } from '@fortawesome/free-regular-svg-icons'

export default function Admissions() {

    const [isLoading, setIsLoading] = useState(true)
    const [admissionDetails, setAdmissionDetails] = useState([])

    useEffect(() => {

        const admissionFetchAPI = fetchApiData("admissions/?filter[orderby]=date&order=asc&per_page=10", false, true).then((jsonResponse) => {
            const allAdmissions = [];

            jsonResponse.data.forEach(admission => {

                let whatsapp_message = null, whatsapp_number = null
                
                if(admission.whatsapp_message){
                    let whatsappMessageArray = admission.whatsapp_message.split("&phone=");

                    whatsapp_message = whatsappMessageArray[0];
                    whatsapp_number = whatsappMessageArray[1];
                }
                
                
                let admissionDetails = {
                    title: parse(admission.title.rendered),
                    // description: decode(admission.content.rendered).replace(/<[^>]+>/g, ''),
                    description: parse(admission.content.rendered),
                    whatsapp_message: whatsapp_message,
                    whatsapp_number: whatsapp_number,
                    register_payment_form_url: (admission.register_payment_form_url) ? admission.register_payment_form_url : null,
                    accordion_label_color: (admission.accordion_label_color) ? admission.accordion_label_color : '#000000',
                };

                allAdmissions.push(admissionDetails)

            })

            setAdmissionDetails(allAdmissions)
            setIsLoading(false)

            console.log(allAdmissions);
        }).catch(error => console.log("Error while fetching data:", error))
        
        return () => {
            
        }
    }, [])

    return (
        <div id="content-area" className="container">
            <PageHeader 
                headerImage={require('../assets/images/admissions-lg-icon.png').default} 
                headerColor='#feccd6' 
                headerTitleSpan='how to apply for ' 
                headerTitleName='ADMISSIONS' 
            />
            <div id="accordion" className="admissions-content">
            {
                (!isLoading) ?
                (
                    admissionDetails.map((admission, index) => {
                        return(
                            <div key={index} className="card">
                                <div className="card-header collapsed accordion-card-header" id={"admissionHeading"+index} data-toggle="collapse" data-target={"#admissionDetail"+index} aria-expanded="true" aria-controls={"admissionDetail"+index}>
                                    <h5 className="mb-0">
                                        <button className="btn btn-link accordion-label" style={{color: admission.accordion_label_color}}>
                                            {admission.title}
                                        </button>
                                    </h5>
                                </div>

                                <div id={"admissionDetail"+index} className={(index==0) ? "collapse show" : "collapse"} aria-labelledby={"admissionHeading"+index} data-parent="#accordion">
                                    <div className="card-body accordion-description">
                                        {admission.description}
                                    </div>

                                    <div className="admission-btns-wrapper p-3">
                                        {
                                            (admission.register_payment_form_url) ?
                                            (
                                                <a target="_blank" href={admission.register_payment_form_url} type="button" className="btn btn-primary admissions-btn mb-2">
                                                    <FontAwesomeIcon icon={faFileAlt} /> Register/Pay
                                                </a>
                                            ) : null
                                        }
                                        
                                        {
                                            (admission.whatsapp_number && admission.whatsapp_message) ? 
                                            (
                                                <a target="_blank" href={encodeURI("https://wa.me/"+admission.whatsapp_number+"?text="+admission.whatsapp_message)} type="button" className="btn btn-success admissions-btn mb-2">
                                                    <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
                                                </a>
                                            ) : null
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : <div className="text-center"><div className="spinner-border text-danger" style={{width: '4rem', height: '4rem'}}></div></div>
            }

            </div>

        </div>
    )
}
