import { useState, useContext, useEffect, useRef } from 'react';
import mainContext from '../context/Context';
import Forgot_Password from './Forgot_Password';
import $ from 'jquery';

import {
    BrowserRouter as Router,
    NavLink,
} from "react-router-dom";

import '../assets/stylesheets/Login.css';

const appstore_icon = require('../assets/images/apple-store.png').default;
const playstore_icon = require('../assets/images/google-store.png').default;


export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const emailRef = useRef('')
    const passwordRef = useRef('')
    const [showLoginBox, setShowLoginBox] = useState(true)
    const [passwordHidden, setPasswordHidden] = useState(true)

    const { loggingIn, doLogin, error, setError } = useContext(mainContext);

    useEffect(() => {
        emailRef.current = email;
        return () => {
            
        }
    }, [email])

    useEffect(() => {
        passwordRef.current = password;
        return () => {
            
        }
    }, [password])

    const showHidePassword = () => {
        setPasswordHidden((prevPasswordHiddenState) => !prevPasswordHiddenState)
    }

    const handleLoginSubmit = () => {
        if(emailRef.current === '' || passwordRef.current === '' || emailRef.current == null || passwordRef.current == null){
            // alert(email+" "+password);
          setError("Please enter both email and pasword");
          return
        }else{
          let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
          if (reg.test(emailRef.current) === false) {
            setError("Invalid email format, email must contain '@' and '.'")
            return
          }
        }
        doLogin(emailRef.current, passwordRef.current)
    }

    const closeErrorModal = () => {
        window.$('#loginErrorModal').modal('hide')
        setPassword('')
        setError(null)
    }

    useEffect(() => {
        if(error != null){
            window.$('#loginErrorModal').modal('show')
        }
        return () => {
          
        }
    }, [error])

    useEffect(() => {
        $("#loginForm").on('submit',function(e){
            e.preventDefault();
            handleLoginSubmit();
        });
        return () => {
            
        }
    }, [])

    return(
        <>
        {/* <!-- Modal --> */}
        <div className="modal fade" id="loginErrorModal" tabIndex="-1" role="dialog" aria-labelledby="loginErrorModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="loginErrorModalLabel">Error</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeErrorModal}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {error}
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeErrorModal} data-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
        </div>

        <div className="container">
            <div className="logo">
                <a href="https://sheebasplayplace.com/"><img src="assets/spp-login-logo.png" alt=""/></a>
            </div>
            <div className="login-text">
            <h1>Welcome to Sheeba's</h1>
                <p>
                    Our distance learning programs are designed for children 1.5 to 3.5 years old and focuses on the complete holistic development of children in a structured, convenient and enjoyable manner.
                </p>

                <p>
                    Happy Learning!
                </p>
                <input type="button" className="readmore-btn" value="Read More" style={{display: "none"}} />
            </div>

            {
                (showLoginBox) ? (
                    <div className="login-form">
                        {
                            (loggingIn) ?
                            (
                                <div id="signin-loading">
                                    <div className="spinner-border text-danger" style={{width: '4rem', height: '4rem'}}></div>
                                </div>
                            ) : null
                        }
                        
                        <h1>SIGN IN</h1>
                        <form id="loginForm">
                            <input type="text" className="username" placeholder="User Email" value={email} onChange={(email) => setEmail(email.currentTarget.value.trim())}/>
                            <input type={(passwordHidden)? "password" : "text"} className="password" placeholder="Password" value={password} onChange={(password) => setPassword(password.currentTarget.value)} />
                            <div className="mb-3"><input type="checkbox" onChange={showHidePassword} /> Show Password</div>
                            <input type="submit" className="login-btn" value="Sign In"/>
                        </form>
                        <a href="#" className="forgot-password" onClick={() => {setShowLoginBox(false)}}>Forgot Password?</a>
                        <div className="mobile-app--wrapper">
                            <a href="https://apps.apple.com/us/app/sheebas-play-place/id1582794078" target="_blank" className="mobile-app-icons mr-1"> <img src={appstore_icon} alt="Apple App Store Icon" /> </a>
                            <a href="https://play.google.com/store/apps/details?id=com.eteamid.spp" target="_blank" className="mobile-app-icons"> <img src={playstore_icon} alt="Google Play App Store Icon" /> </a>
                        </div>
                    </div>
                ) : <Forgot_Password setShowLoginBox={setShowLoginBox} />
            }
            
        </div>
    </>
  )
}