import React, {useEffect} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect,
} from "react-router-dom";

import $ from 'jquery';

import Login from '../pages/Login';

const logo = require('../assets/images/spp-login-logo.png').default;

export default function GuestNavigation() {

    useEffect(() => {
        $('body').addClass('login-bg');
        return () => {
            $('body').removeClass('login-bg');
        }
    }, [])

    return (
        <Router>
            
            <div className="header">
                <div className="logo text-center" style={{float: 'initial'}}><a href="https://sheebasplayplace.com/" target="_blank"><img src={logo} alt=""/></a></div>
            </div> 
                <div className="clearfix"></div>

                {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                <Switch>
                    <Route exact path="/signin">
                        <Login />
                    </Route>
                    <Route path="*">
                        <Redirect to="/signin" />
                    </Route>
                </Switch>
            
        </Router>
    )
}
