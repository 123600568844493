import { useState, useEffect, useContext } from "react"
import { fetchApiData } from '../services/api';
import mainContext from '../context/Context';
import { categories } from '../const/const'
import PageHeader from "../components/PageHeader";
import {decode} from 'html-entities';

export default function LatestUpdates() {

    const { userProfile } = useContext(mainContext);
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [endpointState, setEndpointState] = useState("announcements?categories="+categories.ANNOUNCEMENTS);
    const [totalPages, settotalPages] = useState()


    const handleNavNext = () => {
        setIsLoading(true)
        if(page < totalPages){
            setPage((prevState) => prevState + 1);
        }
    }

    useEffect(() => {
        if(page != 1){
            setEndpointState(endpointState+"&page="+page) 
        }
        return () => {
            
        }
    }, [page])

    useEffect(() => {

        setIsLoading(true);
        var user_role = (userProfile) ? userProfile.role : 'guest';

        const postDetailFetchingAPI = fetchApiData(endpointState+"&user_role="+user_role, true, false).then((jsonResponse) => {
            settotalPages(jsonResponse.totalPages);
            let allPosts = [];
            
            jsonResponse.data.forEach(post => {
                
                console.log(post);

                // post Title
                // let title = post.title.rendered;
                let title = decode(post.title.rendered);
                let content = decode(post.content.rendered).replace(/<[^>]+>/g, '');
                
                // post object
                let postDetails = {
                    featured_image: post.fimg_url,
                    title: title,
                    content: content,
                    facebook_link: post.facebook_link,
                    instagram_link: post.instagram_link,
                    study_plan_file: post.study_plan_file,
                    post_date: post.post_date,
                };

                allPosts.push(postDetails)
            });

            // setPosts(allPosts);
            setPosts((prevPosts) => ([...prevPosts, ...allPosts] ));
            setIsLoading(false);

            console.log(allPosts);
            
            
        }).catch(error => console.log("Error while fetching Latest news and Updates posts:", error));
        return () => {
            
        }
    }, [endpointState])

    return (
        <div id="content-area" className="container">
            <PageHeader 
                headerImage={require('../assets/images/newsupdates-lg-icon.png').default} 
                headerColor='#fff1b2' 
                headerTitleSpan='latest news & ' 
                headerTitleName='UPDATES' 
            />
            
            
            {/* <!--Content START--> */}

            <div className="updates-content">
                {
                    posts.map((post, index) => (
                        <div key={index} className="post-item updates">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <td colSpan="2" className="post-img"><img src={post.featured_image}/></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="post-title">
                                        <h2><strong>{post.title}</strong></h2>
                                        <p>
                                            {post.content}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%" valign="middle" className="category"><img src={require('../assets/images/calender.png').default} width="26" height="28"/> <strong>{post.post_date}</strong></td>
                                    <td width="50%" align="right" valign="middle" className="category">
                                        {
                                            (post.study_plan_file) ?
                                            (
                                                <a href={post.study_plan_file} target="_blank" className="socialLinks studyPlanBtn">
                                                    <img src={require('../assets/images/study-plan.png').default} width="34" height="34" alt=""/> Study Plan
                                                </a>
                                            ) : null
                                        } 
                                        {
                                            (post.facebook_link) ?
                                            (
                                                <a href={post.facebook_link} target="_blank" className="socialLinks">
                                                    <img src={require('../assets/images/facebook.png').default} width="34" height="34" alt=""/> 
                                                </a>
                                            ) : null
                                        }
                                        
                                        {
                                            (post.instagram_link) ?
                                            (
                                                <a href={post.instagram_link} target="_blank" className="socialLinks">
                                                    <img src={require('../assets/images/instagram.png').default} width="34" height="34" alt=""/>
                                                </a>
                                            ) : null
                                        }
                                        
                                    </td>
                                    
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    ))
                }

            </div>
            <div className="mt-4 text-center">
                {
                    (!isLoading) ? 
                    (
                        (posts.length > 0) ? 
                        (
                            
                            (page < totalPages) ?
                            (
                                
                                <button type="button" class="btn btn-primary view-more-btn" onClick={handleNavNext}>
                                    View More
                                </button>
                            
                            ) : <p>No More Posts</p>    
                            
                        ): <p>There are no posts</p>
                    ) : <div class="spinner-border text-danger" style={{width: '4rem', height: '4rem'}}></div>
                }         
            </div>
            {/* <!--Content END--> */}
        </div>
    )
}
