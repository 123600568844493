import {useState, useEffect} from 'react'
import { fetchApiData } from '../services/api';
import parse from 'html-react-parser';
import PageHeader from '../components/PageHeader';

export default function Curriculum() {

    const [isLoading, setIsLoading] = useState(true)
    const [curriculumDetails, setCurriculumDetails] = useState([])

    useEffect(() => {

        const curriculumFetchAPI = fetchApiData("curriculum/?filter[orderby]=date&order=asc&per_page=10", false, true).then((jsonResponse) => {
            const allCurriculum = [];

            jsonResponse.data.forEach(curriculum => {

                
                let curriculumDetails = {
                    title: parse(curriculum.title.rendered),
                    // description: decode(curriculum.content.rendered).replace(/<[^>]+>/g, ''),
                    description: parse(curriculum.content.rendered),
                };

                allCurriculum.push(curriculumDetails)

            })

            setCurriculumDetails(allCurriculum)
            setIsLoading(false)

            console.log(allCurriculum);
        }).catch(error => console.log("Error while fetching data:", error))
        
        return () => {
            
        }
    }, [])

    return (
        <div id="content-area" className="container">
            
            <PageHeader 
                headerImage={require('../assets/images/curriculum-lg-icon.png').default} 
                headerColor='#b5f4f4' 
                headerTitleSpan='on-campus, off-campus ' 
                headerTitleName='CURRICULUM' 
            />

            <div id="accordion" className="curriculum-content">
                {
                    (!isLoading) ?
                    (
                        curriculumDetails.map((curriculum, index) => {
                            return(
                                <div key={index} className="card">
                                    <div className="card-header collapsed accordion-card-header" id={"admissionHeading"+index} data-toggle="collapse" data-target={"#admissionDetail"+index} aria-expanded="true" aria-controls={"admissionDetail"+index}>
                                        <h5 className="mb-0">
                                            <button className="btn btn-link accordion-label">
                                                {curriculum.title}
                                            </button>
                                        </h5>
                                    </div>

                                    <div id={"admissionDetail"+index} className={(index==0) ? "collapse show" : "collapse"} aria-labelledby={"admissionHeading"+index} data-parent="#accordion">
                                        <div className="card-body accordion-description">
                                            {curriculum.description}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : <div className="text-center"><div className="spinner-border text-danger" style={{width: '4rem', height: '4rem'}}></div></div>
                }
            </div>
        </div>
    )
}
