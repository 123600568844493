import { useContext, useState, useEffect, useRef } from 'react';
import mainContext from '../context/Context';
import ImageSlider from "../components/ImageSlider"
import LatestPosts from "../components/LatestPosts"
import { categories } from '../const/const'

export default function Home() {
    const { userProfile } = useContext(mainContext);
    const [endpoint, setEndpoint] = useState("")
    const [endpointCreated, setEndpointCreated] = useState(false)

    const [isDistanceLearningUser, setIsDistanceLearningUser] = useState(false)

    useEffect(() => {
        
        switch (userProfile.role) {
        case 'playgroupclass':
            setEndpoint("posts/?posts_per_page=4&categories="+categories.VIDEOS_PG+","+categories.TRAINING_PG+","+categories.YOUTUBE)
            setEndpointCreated(true)
            break;

        case 'pre-nursery':
            setEndpoint("posts/?posts_per_page=4&categories="+categories.VIDEOS_PN+","+categories.TRAINING_PN+","+categories.YOUTUBE)
            setEndpointCreated(true)
            break;

        case 'pre-nurserydl1':
        case 'pre-nurserydl2':
        case 'pre-nurserydl3':
        case 'pre-nurserydl4':
        case 'pre-nurserydl5':
        case 'pre-nurserydl6':
        case 'pre-nurserydl7':
        case 'pre-nurserydl8':
        case 'pre-nurserydl9':
        case 'pre-nurserydl10':
        case 'pre-nurserydl11':
        case 'pre-nurserydl12':
            setEndpoint("posts?posts_per_page=4&categories="+categories.VIDEOS_PN+","+categories.TRAINING_PN+","+categories.YOUTUBE+"&user_role=" + userProfile.role);

            // set that the user is distance learning user
            setIsDistanceLearningUser(true);
            setEndpointCreated(true)
            break;

        case 'playgroupdl1':
        case 'playgroupdl2':
        case 'playgroupdl3':
        case 'playgroupdl4':
        case 'playgroupdl5':
        case 'playgroupdl6':
        case 'playgroupdl7':
        case 'playgroupdl8':
        case 'playgroupdl9':
        case 'playgroupdl10':
        case 'playgroupdl11':
        case 'playgroupdl12':
            setEndpoint("posts?posts_per_page=4&categories="+categories.VIDEOS_PG+","+categories.TRAINING_PG+","+categories.YOUTUBE+"&user_role=" + userProfile.role);

            // set that the user is distance learning user
            setIsDistanceLearningUser(true);
            setEndpointCreated(true)
            break;
    
        default:
            break;
    }
        
        return () => {
        
        }
    }, [])

    return (
        <div id="content-area" className="container">
            <ImageSlider />
            {/* <!--Content START--> */}
         <div className="content">
            <h1 className="text-center">Latest Videos @ Sheeba’s</h1>
            {endpointCreated == true ? 
                (
                    <LatestPosts isDistanceLearningUser={isDistanceLearningUser} endpoint={endpoint}/>
                ): null
            }
            
        </div>
        </div>
    )
}
