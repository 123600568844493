const domain = 'https://portal.sheebasplayplace.com/';

const loginUrl = domain + 'wp-react-login.php';
const sliderUrl = domain + 'getSliderImages.php?';

const categories = {
    YOUTUBE : 'youtube',
    VIDEOS_PG: 'videos-pg',
    VIDEOS_PN: 'videos-pn',
    TRAINING_PG: 'training-pg',
    TRAINING_PN: 'training-pn',
    ANNOUNCEMENTS: 'announcements',
}

const postSlugs = {
    FORGOT_PASSWORD: 'forgot-password',
}

export { domain, loginUrl, sliderUrl, categories, postSlugs };