import React, {useContext} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect
} from "react-router-dom";

import Home from '../pages/Home'
import Admissions from '../pages/Admissions';
import Curriculum from '../pages/Curriculum';
import Videos from '../pages/Videos';
import Training from '../pages/Training';
import Youtube from '../pages/Youtube';
import LatestUpdates from '../pages/LatestUpdates';
import mainContext from '../context/Context';

const logo = require('../assets/images/spp-logo.jpg').default;

export default function LoggedInNavigation() {
    const {userProfile, doLogout} = useContext(mainContext)

    const _handleLogout = () => {
        doLogout();
        window.$('#logoutModal').modal('hide')
    }

    let categoryName;

    if(userProfile){

        switch (userProfile.role) {
            case "pre-nursery":
                categoryName = "Pre-Nursery";
                break;
            
            case "playgroupclass":
                categoryName = "Playgroup";
                break;

            case 'pre-nurserydl1':
                categoryName = "Pre-Nursery DL / Folder: 01";
                break;
            case 'pre-nurserydl2':
                categoryName = "Pre-Nursery DL / Folder: 02";
                break;
            case 'pre-nurserydl3':
                categoryName = "Pre-Nursery DL / Folder: 03";
                break;
            case 'pre-nurserydl4':
                categoryName = "Pre-Nursery DL / Folder: 04";
                break;
            case 'pre-nurserydl5':
                categoryName = "Pre-Nursery DL / Folder: 05";
                break;
            case 'pre-nurserydl6':
                categoryName = "Pre-Nursery DL / Folder: 06";
                break;
            case 'pre-nurserydl7':
                categoryName = "Pre-Nursery DL / Folder: 07";
                break;
            case 'pre-nurserydl8':
                categoryName = "Pre-Nursery DL / Folder: 08";
                break;
            case 'pre-nurserydl9':
                categoryName = "Pre-Nursery DL / Folder: 09";
                break;
            case 'pre-nurserydl10':
                categoryName = "Pre-Nursery DL / Folder: 10";
                break;
            case 'pre-nurserydl11':
                categoryName = "Pre-Nursery DL / Folder: 11";
                break;
            case 'pre-nurserydl12':
                categoryName = "Pre-Nursery DL / Folder: 12";
                break;

            case 'playgroupdl1':
                categoryName = "Playgroup DL / Folder: 01"
                break;
            case 'playgroupdl2':
                categoryName = "Playgroup DL / Folder: 02"
                break;
            case 'playgroupdl3':
                categoryName = "Playgroup DL / Folder: 03"
                break;
            case 'playgroupdl4':
                categoryName = "Playgroup DL / Folder: 04"
                break;
            case 'playgroupdl5':
                categoryName = "Playgroup DL / Folder: 05"
                break;
            case 'playgroupdl6':
                categoryName = "Playgroup DL / Folder: 06"
                break;
            case 'playgroupdl7':
                categoryName = "Playgroup DL / Folder: 07"
                break;
            case 'playgroupdl8':
                categoryName = "Playgroup DL / Folder: 08"
                break;
            case 'playgroupdl9':
                categoryName = "Playgroup DL / Folder: 09"
                break;
            case 'playgroupdl10':
                categoryName = "Playgroup DL / Folder: 10"
                break;
            case 'playgroupdl11':
                categoryName = "Playgroup DL / Folder: 11"
                break;
            case 'playgroupdl12':
                categoryName = "Playgroup DL / Folder: 12"
                break;

            default:
                categoryName = null;
                break;
        }
    }

    return (
        <Router>
            <div className="header">

                <div className="logo"><NavLink to="/home" style={{display:'block'}}><img src={logo} alt=""/></NavLink></div>
                <div className="user-loggedin">
                    {
                        (userProfile) ? (
                            <>
                                <h3>Welcome to {categoryName}</h3>
                                <h2>{userProfile.name}</h2>            
                            </>
                        ) : null
                    }
                </div>

                <div className="menu">
                    <ul id="desktop-menu" className="nav nav-pills">
                        <li className="nav-item"><NavLink activeClassName="active" to="/home" className="nav-link">Home</NavLink></li>
                        <li className="nav-item"><NavLink activeClassName="active" to="/curriculum" className="nav-link">Curriculum</NavLink></li>
                        <li className="nav-item"><NavLink activeClassName="active" to="/admissions" className="nav-link">Admissions</NavLink></li>
                        <li className="nav-item"><NavLink activeClassName="active" to="/videos" className="nav-link">Children</NavLink></li>
                        <li className="nav-item"><NavLink activeClassName="active" to="/training" className="nav-link">Parents</NavLink></li>
                        <li className="nav-item"><NavLink activeClassName="active" to="/youtube" className="nav-link">Youtube</NavLink></li>
                        <li className="nav-item"><NavLink activeClassName="active" to="/latestUpdates" className="nav-link">Latest Updates</NavLink></li>
                        <li className="nav-item"><a href="#" className="nav-link refresh-link" style={{color: '#005baa'}} onClick={() => window.location.reload()}>Refresh App</a></li> 
                        <li className="nav-item"><a href="#" className="nav-link sign-out-link" onClick={() => window.$('#logoutModal').modal('show')}>Sign Out</a></li> 
                    </ul>

                    <nav id="mobile-menu" role="navigation">
                        <div id="menuToggle">
                            <input type="checkbox" />
                                <span></span>
                                <span></span>
                                <span></span>
                            <ul id="menu">
                            <li><NavLink activeClassName="active" to="/home" className="nav-link">Home</NavLink></li>
                            <li><NavLink activeClassName="active" to="/curriculum" className="nav-link">Curriculum</NavLink></li>
                            <li><NavLink activeClassName="active" to="/admissions" className="nav-link">Admissions</NavLink></li>
                            <li><NavLink activeClassName="active" to="/videos" className="nav-link">Children</NavLink></li>
                            <li><NavLink activeClassName="active" to="/training" className="nav-link">Parents</NavLink></li>
                            <li><NavLink activeClassName="active" to="/youtube" className="nav-link">Youtube</NavLink></li>
                            <li><NavLink activeClassName="active" to="/latestUpdates" className="nav-link">Latest Updates</NavLink></li>
                            <li><a href="#" className="nav-link refresh-link" style={{color: '#005baa'}} onClick={() => window.location.reload()}>Refresh App</a></li> 
                            <li><a href="#" className="nav-link sign-out-link" onClick={() => window.$('#logoutModal').modal('show')}>Sign Out</a></li> 
                            </ul>
                        </div>
                    </nav>

                </div>



            </div> 
            <div className="clearfix"></div>

            {/* <!-- Button trigger modal --> */}

            {/* <!-- Modal --> */}
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="logoutModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="logoutModalLabel">Confirm Sign Out</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{borderWidth: 0}}>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    Are you sure you want to Sign out?
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" className="btn btn-primary" style={{backgroundColor: '#f22244', borderWidth: 0}} onClick={_handleLogout}>Sign Out</button>
                </div>
                </div>
            </div>
            </div>

                {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                <Switch>
                    <Route exact path="/home">
                        <Home />
                    </Route>
                    <Route path="/videos">
                        <Videos />
                    </Route>
                    <Route path="/training">
                        <Training />
                    </Route>
                    <Route path="/curriculum">
                        <Curriculum />
                    </Route>
                    <Route path="/admissions">
                        <Admissions />
                    </Route>
                    <Route path="/youtube">
                        <Youtube />
                    </Route>
                    <Route path="/latestUpdates">
                        <LatestUpdates />
                    </Route>
                    <Route path="*">
                        <Redirect to="/home" />
                    </Route>
                </Switch>
            {/* </div> */}
        </Router>
    )
}
