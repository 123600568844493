import React from 'react'
import LatestPosts from '../components/LatestPosts'
import PageHeader from '../components/PageHeader'
import { categories } from '../const/const'

export default function Youtube() {
    return (
        <div id="content-area" className="container">
            <PageHeader 
                headerImage={require('../assets/images/youtube-lg-icon.png').default} 
                headerColor='#fae5f7' 
                headerTitleSpan='kids content on ' 
                headerTitleName='YOUTUBE' 
            />

            <LatestPosts 
                isDistanceLearningUser={false} 
                endpoint={"posts?categories="+categories.YOUTUBE+"&per_page=10"} 
                categoryName="youtube" 
                categoryIcon={require('../assets/images/youtube-small.png').default} 
                pagination={true}
            />

        </div>
    )
}
