import { useState, useEffect } from 'react';
import './App.css';
import Footer from './components/Footer';

import GuestNavigation from './components/GuestNavigation';
import LoggedInNavigation from './components/LoggedInNavigation';
import {loginUrl} from './const/const';
import mainContext from './context/Context';

export default function App() {
  // const [token, setToken] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null)
  const [loggingIn, setLoggingIn] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [error, setError] = useState(null)

  // function to check if the currently stored session is valid or not
  const isSessionExists = async (userProfile) => {
    let formData = new FormData();
    formData.append('type', 'isSessionExists');
    formData.append('user_id', userProfile.id);
    formData.append('authToken', userProfile.authToken);

    try {
      let response = await fetch(loginUrl, {
        method: 'POST',
        body: formData,
      });
      
      let json = await response.json();
      console.log("session exist message: ", json.msg);
      

      if(json.status == false){
        return false;
      }else{
        // update user role here
        userProfile.role = json.role;
        return true;
      }

    } catch (error) {
      return false;
    }

  };
  
  // check if we have token stored in database
  useEffect(async () => {
    const value  = localStorage.getItem('userProfile')
      if (value) {
        let localStorageUserProfile = JSON.parse(value);

        const sessionExists = await isSessionExists(localStorageUserProfile);

        if(!sessionExists){
          doLogout();
          setError('Session Expired');
          setIsLoading(false);
          setIsLogged(false);
        }else{
          setUserProfile(localStorageUserProfile);
          setIsLogged(true);
          setIsLoading(false);
          setIsLogged(true);
        }
          
      } else {
        setIsLoading(false);
        setIsLogged(false);
        setIsLogged(false);
      }
  }, []); //Run once

  // function to login
  const doLogin = async (email, password) => {
      
      setLoggingIn(true);
      setError(null);
      let formData = new FormData();
      formData.append('type', 'login');
      formData.append('email', email);
      formData.append('password', password);
      try {
        let response = await fetch(loginUrl, {
          method: 'POST',
          body: formData,
        });
        let json = await response.json();
        
        if (json.status != false) {
          setError(null);
          try {
            await localStorage.setItem(
              'userProfile',
              JSON.stringify({
                isLoggedIn: json.status,
                authToken: json.token,
                id: json.data.user_id,
                name: json.data.display_name,
                avatar: json.avatar,
                role: json.role,
              })
            );
          } catch {
            setError('Error storing data on device');
          }

          setUserProfile({
            isLoggedIn: json.status,
            authToken: json.token,
            id: json.data.id,
            name: json.data.display_name,
            avatar: json.avatar,
            role: json.role,
          });
          setIsLogged(true);
        } else {
          setIsLogged(false);
          setError(json.msg);
          console.log(json.msg);
        }
        setLoggingIn(false);
        setIsLoading(false);
      } catch (error) {
        setError('Error connecting to server, check your internet.');
        setLoggingIn(false);
          console.log(error);
      }
    };

    // function to logout
    const doLogout = async () => {
      try {
        
        await localStorage.removeItem('userProfile');
        setLoggingIn(true);
        setIsLogged(false);
        setUserProfile(null);
        setLoggingIn(false);
      
        return true;
      } catch (exception) {
        console.log(exception);
        setError('Error deleting data');
        return false;
      }
    };

    // exposing states and functions via context api
  const wContext = {
    userProfile: userProfile,
    loggingIn: loggingIn,
    isLogged: isLogged,
    error: error,
    setError: setError,
    setLoggingIn: setLoggingIn,
    doLogin: (email, password) => {
      doLogin(email, password);
    },
    doLogout: async () => {
      doLogout();
    },
  };

  return (
    <mainContext.Provider value={wContext}> 
      <div>
        {
          (!isLoading) ? (userProfile) ? <LoggedInNavigation /> : <GuestNavigation /> : null
        }
        
        <Footer />
      </div>
    </mainContext.Provider>
  );
}
